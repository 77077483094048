<!--
 * @Author: DY
 * @Date: 2020-07-09 17:41:08
 * @LastEditTime: 2021-05-10 18:41:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_dev\src\views\contractControl\earlyWarning.vue
-->
<template>
    <div class="earlyWarning">
        <div class="dialog-box">
            <div class="header-title">
                <h4>消息通知</h4>
            </div>
            <div class="section-main" v-loading="loading">
                <ul class="list">
                    <template v-if="ControlData.length > 0">
                        <li class="item" v-for="(item,index) in ControlData" :key="index">
                            <div class="item-box" :class="itemLogoClassName(item)">
                                <div class="left-logo">
                                    <div class="logo-wrapper">
                                        <div class="logo-bg">
                                            <i v-if="item.group === 301" class="iconfont iconyujing"></i>
                                            <i v-else-if="item.group === 201" class="">管控</i>
                                            <i v-else class=""></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="right-main">
                                    <h6 v-text="textChange(item)">
                                    </h6>
                                    <em>{{ item.created }}</em>
                                    <!-- eslint-disable-next-line vue/no-v-html -->
                                    <p v-html="item.title"></p>
                                </div>
                            </div>
                        </li>
                    </template>
                    <li v-else class="noDate">
                        暂无通知
                    </li>
                </ul>
            </div>
            <div class="footer-btn">
                <button class="btn" @click="Close">
                    关闭
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            // 管控消息
            ControlData: {},
            // 租户id
            tenant_Id: '',
        };
    },
    computed: {},
    watch: {},
    created() {
        if (this.extr) {
            if (this.extr.tenant_Id) {
                this.tenant_Id = this.extr.tenant_Id;
            } else {
                this.tenant_Id = this.$takeTokenParameters('TenantId');
            }
        }
        // 拉取管控消息
        this.getControlList();
    },
    mounted() {},
    destroyed() {},
    methods: {
        Close() {
            this.$parent.hide();
        },
        // 拉取管控消息
        getControlList() {
            this.loading = true;
            this.$axios
                // eslint-disable-next-line max-len
                .get(`/interfaceApi/message/MessageInfo/get_notice_page?tenant_id=${this.tenant_Id}&key_id=${this.extr.control_instance_code}&template_code=${this.extr.control_msg_code}&pageindex=1&pagesize=100`)
                .then(res => {
                    this.ControlData = res.rows.reverse();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        textChange(item) {
            const arr = {
                101: '消息通知',
                201: '管控消息',
                301: '预警消息',
                401: '控制提醒',
            };
            return arr[item.group];
        },
        itemLogoClassName(item) {
            if (item.group === 201) {
                return 'gk';
            } if (item.group === 301) {
                return 'yj';
            }
            return '';
        },
    },
};
</script>
<style lang="stylus" scoped>
.earlyWarning
    width 100%
    height 100%
    padding 0.1rem
    position relative
    background: rgba(255,255,255,0.5);
    .dialog-box
        width 100%
        height 100%
        background #fff
        padding 0.3rem 0 0.1rem
        display flex
        flex-direction column
        .header-title
            height 0.4rem
            line-height 0.4rem
            h4
                color #000
                font-weight 600
                margin-left 0.38rem
                font-size 0.2rem
        .section-main
            flex 1
            overflow hidden
            .list
                overflow-y auto
                height 100%
                .item
                    border-bottom 1px solid rgba(240,240,240,1)
                    padding 0.2rem 0
                    &:last-child
                        border-bottom none
                    .item-box
                        padding 0 0.38rem
                        display flex
                        .left-logo
                            width 0.6rem
                            .logo-wrapper
                                width 0.5rem
                                height 0.5rem
                                background rgba(235,101,111,0.2)
                                border-radius 50%
                                overflow hidden
                                .logo-bg
                                    width 0.36rem
                                    height 0.36rem
                                    background rgba(235,101,111,1)
                                    border-radius 50%
                                    margin 0.07rem
                                    line-height 0.30rem
                                    text-align center
                                    i
                                        color #ffffff
                                        font-size 0.14rem
                        .right-main
                            flex 1
                            h6
                                font-size 0.18rem
                                line-height 0.3rem
                                font-weight 600
                            em
                                font-size 0.14rem
                                color rgba(151,158,167,1)
                                // line-height 0.24rem
                                margin 0.1rem 0
                            p
                                font-size 0.16rem
                                color rgba(51,51,51,1)
                                line-height 0.24rem
                            .operator-wrapper
                                display flex
                                justify-content flex-end
                                margin-top 0.1rem
                                .operator
                                    padding 0.04rem
                                    background rgba(2,133,254,0.08);
                                    font-size 0.12rem
                                    display inline-block
                                    color #0285FE
                                    margin-right 0.2rem
                                    i
                                        font-size 0.12rem
                                .time
                                    padding 0.04rem
                                    border 1px solid rgba(221,221,221,1);
                                    font-size 0.12rem
                                    display inline-block
                                    color #A77B54
                                    i
                                        font-size 0.12rem
                    .yj
                        .right-main
                            h6
                                color #D6000F
                        .left-logo
                            .logo-wrapper
                                background rgba(235,101,111,0.2)
                                .logo-bg
                                    background rgba(235,101,111,1)
                    .gk
                        .right-main
                            h6
                                color #1577D2
                        .left-logo
                            .logo-wrapper
                                background rgba(85,136,241,0.2)
                                .logo-bg
                                    background rgba(85,136,241,1)
                    .js
                        .right-main
                            h6
                                color #FF6600
                        .left-logo
                            .logo-wrapper
                                background rgba(250,132,54,0.2)
                                .logo-bg
                                    background rgba(250,132,54,1)
        .footer-btn
            height 0.44rem
            display flex
            justify-content center
            margin-top 0.2rem
            .btn
                width 1.84rem
                height 0.44rem
                border 0.02rem solid rgba(21,119,210,1)
                color #1577D2
                background #fff
                font-size 0.2rem
                cursor pointer
</style>